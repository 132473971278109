import p5 from 'p5';
import Bubble from './Bubble';

new p5((sketch) => {
	let bubbles = [];
	sketch.setup =  function() {
		var myCanvas = sketch.createCanvas(sketch.windowWidth, sketch.displayHeight);
		
		myCanvas.position (0,0);
		myCanvas.style('z-index','-1');

		for (var i = 0; i<10; i++) {
			var x = sketch.random(sketch.width);
			// var y = sketch.random(sketch.height);
			var y = sketch.random(500,1000);
			var r = sketch.random(40, 80);
			var c = sketch.color(246, 248, 250);
			let b = new Bubble(
				x,
				y,
				r,
				c, 
				sketch.map(r, 20, 30, 0.5, 0.75),
				sketch);
	
	
			bubbles.push(b);
		
		}
	};

	sketch.draw = function() {
		sketch.background(255);
		for (let i = 0; i < bubbles.length; i++) {
			if (bubbles[i].contains(sketch.mouseX, sketch.mouseY)) {
			  bubbles[i].react();
			} else {
			}
			bubbles[i].move();
			bubbles[i].show();
		  }
	  };

	sketch.windowResized = function() {
		sketch.resizeCanvas(sketch.windowWidth,sketch.displayHeight);
	}; 
})




