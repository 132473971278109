export default class Bubble {
	constructor(x,y,r,c, gravity, sketch){
	this.x = x;
	this.y = y;
	this.r = r;
	this.c = c;
	this.gravity = gravity;
	this.sketch = sketch;
	}

	
	react(){
		this.x = this.sketch.lerp(this.x, this.x + this.sketch.random(-100, 100), -0.0075);
		this.y = this.sketch.lerp(this.y, this.y + this.sketch.random(0, -400), 0.025);
		this.y +=this.gravity;
        	
	}
	
	contains(px, py){
		let d = this.sketch.dist(px, py, this.x, this.y);
		if (d<this.r+250){
			return true;
		} else {	
			return false;}
		}
	
	
	move(){
	this.x = this.sketch.lerp(this.x,this.x + this.sketch.random(-1,1),0.5);
			// this.y = this.y + random(-1,1);
	this.y = this.sketch.lerp(this.y,this.y + this.gravity,0.3);                                                          
	this.y = this.sketch.constrain (this.y, 0, this.sketch.height);
	this.x = this.sketch.constrain (this.x, 0, this.sketch.width);
	
	
	}
	
	show(){
		this.sketch.fill(this.c);
		this.sketch.noStroke();
		this.sketch.ellipse(this.x, this.y, this.r*10);
	
	}
}
